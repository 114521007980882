import React, { useState, useEffect } from 'react';
import axios from 'axios';
import io from "socket.io-client";
const socket = io.connect(process.env.REACT_APP_API_BASE_URL, {
  reconnection: true,
  reconnectionAttempts: Infinity,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000
});

function App() {
  const [languages, setLanguages] = useState([]);
  // Video to Video
  const [selectedVVSourceLanguage, setSelectedVVSourceLanguage] = useState([]);
  const [selectedVVTargetLanguage, setSelectedVVTargetLanguage] = useState('');
  const [selectedVVVoice, setSelectedVVVoice] = useState('');
  const [vVUrl, setVVUrl] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [vVError, setVVError] = useState('');
  const [error, setError] = useState('');
  const [vVLoading, setVVLoading] = useState(false);
  // Video to Text
  const [selectedVTSourceLanguage, setSelectedVTSourceLanguage] = useState([]);
  const [selectedVTTargetLanguage, setSelectedVTTargetLanguage] = useState('');
  const [vTUrl, setVTUrl] = useState('');
  const [vTTranslatedText, setVTTranslatedText] = useState('');
  const [vTError, setVTError] = useState('');
  const [vTLoading, setVTLoading] = useState(false);
  // Audio to Text
  const [selectedATSourceLanguage, setSelectedATSourceLanguage] = useState([]);
  const [selectedATTargetLanguage, setSelectedATTargetLanguage] = useState('');
  const [aTUrl, setATUrl] = useState('');
  const [aTTranslatedText, setATTranslatedText] = useState('');
  const [aTError, setATError] = useState('');
  const [aTLoading, setATLoading] = useState(false);
  // Text to Audio
  const [selectedTATargetLanguage, setSelectedTATargetLanguage] = useState('');
  const [selectedTAVoice, setSelectedTAVoice] = useState('');
  const [tAText, setTAText] = useState('');
  const [audioUrl, setAudioUrl] = useState('');
  const [tAError, setTAError] = useState('');
  const [tALoading, setTALoading] = useState(false);
  // Text to Audio
  const [selectedTTTargetLanguage, setSelectedTTTargetLanguage] = useState('');
  const [tTText, setTTText] = useState('');
  const [tTTranslatedText, setTTTranslatedText] = useState('');
  const [tTError, setTTError] = useState('');
  const [tTLoading, setTTLoading] = useState(false);


  useEffect(() => {
    // Fetch languages from API (assuming the same structure for simplicity)
    // Replace `your_api_endpoint` with your actual API endpoint
    axios.get(process.env.REACT_APP_API_BASE_URL + 'get_languages').then((response) => {
      setLanguages(response.data);
    }).catch((error) => {
      setError('Error fetching languages from the server.');
      console.error('Error fetching languages:', error);
    });

    // socket.on("video_file", (data) => {
    //   // console.log("Received:", data.data);
    //   setMessageReceived(data.message);
    // });
    socket.on("video_file", (data) => {
      const blob = new Blob([data.data], { type: 'video/mp4' });
      const url = URL.createObjectURL(blob);
      setVideoUrl(url);
    });

    socket.on("full_video_file", (data) => {
      setVideoUrl(data.url);
      setVVLoading(false)
    });

    socket.on('vverror', function(data) {
      alert('Error received: ' + data.error);
      console.log('Error received:', data.error);
      setVVLoading(false)
    });
  

    return () => {
      socket.off('video_file');
      socket.off('full_video_file');
    };
  }, []);


  const handleVVSubmit = (e) => {
    e.preventDefault();
    setVVLoading(true);
    socket.emit("process_video_video", { details: {"youtube_url": vVUrl,
        "input_languages": selectedVVSourceLanguage,
        "target_language": selectedVVTargetLanguage,
        "target_voice": selectedVVVoice
      }});
    // Setting responseType as 'blob' to receive the response as a Blob
    // axios.post(process.env.REACT_APP_API_BASE_URL + 'url_translate', {
    //   youtube_url: vVUrl,
    //   input_languages: selectedVVSourceLanguage,
    //   target_language: selectedVVTargetLanguage,
    //   target_voice: selectedVVVoice,
    // }).then((response) => {
    //   setVideoUrl(response.data.url);
    //   setError('');
    // }).catch((error) => {
    //   if (error.response) {
    //     setVVError(`API returned error: ${error.response.status} - ${error.response.data.message}`);
    //     console.error('API returned error:', error.response.status, error.response.data.message);
    //   } else if (error.request) {
    //     setVVError('No response received from the server. Please try again later.');
    //     console.error('No response received from the server:', error.request);
    //   } else {
    //     setVVError('An error occurred while processing the request. Please try again later.');
    //     console.error('An error occurred while processing the request:', error.message);
    //   }
    // }).finally(() => {
    //   setVVLoading(false);
    // });
  };


  const handleVTSubmit = (e) => {
    e.preventDefault();
    setVTLoading(true);
    // Setting responseType as 'blob' to receive the response as a Blob
    axios.post(process.env.REACT_APP_API_BASE_URL + 'url_to_text_translate', {
      youtube_url: vTUrl,
      input_languages: selectedVTSourceLanguage,
      target_language: selectedVTTargetLanguage,
    }).then((response) => {
      setVTTranslatedText(response.data.translated_text);
      setVTError('');
    }).catch((error) => {
      if (error.response) {
        setVTError(`API returned error: ${error.response.status} - ${error.response.data.message}`);
        console.error('API returned error:', error.response.status, error.response.data.message);
      } else if (error.request) {
        setVTError('No response received from the server. Please try again later.');
        console.error('No response received from the server:', error.request);
      } else {
        setVTError('An error occurred while processing the request. Please try again later.');
        console.error('An error occurred while processing the request:', error.message);
      }
    }).finally(() => {
      setVTLoading(false);
    });
  };

  
  const handleATSubmit = (e) => {
    e.preventDefault();
    setATLoading(true);
    // Setting responseType as 'blob' to receive the response as a Blob
    axios.post(process.env.REACT_APP_API_BASE_URL + 'audio_to_text', {
      audio_url: aTUrl,
      input_languages: selectedATSourceLanguage,
      target_language: selectedATTargetLanguage,
    }).then((response) => {
      setATTranslatedText(response.data.translated_text);
      setATError('');
    }).catch((error) => {
      if (error.response) {
        setATError(`API returned error: ${error.response.status} - ${error.response.data.message}`);
        console.error('API returned error:', error.response.status, error.response.data.message);
      } else if (error.request) {
        setATError('No response received from the server. Please try again later.');
        console.error('No response received from the server:', error.request);
      } else {
        setATError('An error occurred while processing the request. Please try again later.');
        console.error('An error occurred while processing the request:', error.message);
      }
    }).finally(() => {
      setATLoading(false);
    });
  };

  const handleTASubmit = (e) => {
    e.preventDefault();
    setTALoading(true);
    // Setting responseType as 'blob' to receive the response as a Blob
    axios.post(process.env.REACT_APP_API_BASE_URL + 'text_to_audio', {
      text: tAText,
      target_language: selectedTATargetLanguage,
      target_voice: selectedTAVoice
    }).then((response) => {
      setAudioUrl(response.data.url);
      setTAError('');
    }).catch((error) => {
      if (error.response) {
        setTAError(`API returned error: ${error.response.status} - ${error.response.data.message}`);
        console.error('API returned error:', error.response.status, error.response.data.message);
      } else if (error.request) {
        setTAError('No response received from the server. Please try again later.');
        console.error('No response received from the server:', error.request);
      } else {
        setTAError('An error occurred while processing the request. Please try again later.');
        console.error('An error occurred while processing the request:', error.message);
      }
    }).finally(() => {
      setTALoading(false);
    });
  };

  const handleTTSubmit = (e) => {
    e.preventDefault();
    setTTLoading(true);
    // Setting responseType as 'blob' to receive the response as a Blob
    axios.post(process.env.REACT_APP_API_BASE_URL + 'text_to_text_translate', {
      text: tTText,
      target_language: selectedTTTargetLanguage,
    }).then((response) => {
      setTTTranslatedText(response.data.text);
      setTTError('');
    }).catch((error) => {
      if (error.response) {
        setTTError(`API returned error: ${error.response.status} - ${error.response.data.message}`);
        console.error('API returned error:', error.response.status, error.response.data.message);
      } else if (error.request) {
        setTTError('No response received from the server. Please try again later.');
        console.error('No response received from the server:', error.request);
      } else {
        setTTError('An error occurred while processing the request. Please try again later.');
        console.error('An error occurred while processing the request:', error.message);
      }
    }).finally(() => {
      setTTLoading(false);
    });
  };

  const getVoices = (languageCode) => {
    const language = languages.find(lang => lang.code === languageCode);
    return language ? language.voices : [];
  };

  return (
    <div>
      <h2>Convert Youtube video to another language </h2>
      <form onSubmit={handleVVSubmit}>
        <input type="text" value={vVUrl} onChange={(e) => setVVUrl(e.target.value)} placeholder="Paste URL here" required />
        <select multiple value={selectedVVSourceLanguage} onChange={(e) => {
          const clickedOption = e.target.value;
          let updatedValues;
          // If the clicked option is already selected, deselect it
          if (selectedVVSourceLanguage.includes(clickedOption)) {
            updatedValues = selectedVVSourceLanguage.filter(option => option !== clickedOption);
          } else { // If the clicked option is not selected, select it
            updatedValues = [...selectedVVSourceLanguage, clickedOption];
          }
          setSelectedVVSourceLanguage(updatedValues);
        }} required>
          <option value="">Select Source Language(s)</option>
          {languages.map((language) => (
            <option key={language.code} value={language.code}>{language.language}</option>
          ))}
        </select>
        <select value={selectedVVTargetLanguage} onChange={(e) => setSelectedVVTargetLanguage(e.target.value)} required>
          <option value="">Select Target Language</option>
          {languages.map((language) => (
            <option key={language.code} value={language.code}>{language.language}</option>
          ))}
        </select>
        {selectedVVTargetLanguage && (
          <select value={selectedVVVoice} onChange={(e) => setSelectedVVVoice(e.target.value)} required>
            <option value="">Select Target Voice</option>
            {getVoices(selectedVVTargetLanguage).map((voice) => (
              <option key={voice.voice} value={voice.voice}>{voice.gender} - {voice.voice}</option>
            ))}
          </select>
        )}
        <button type="submit" disabled={vVLoading}>{vVLoading ? 'Translating Video...' : 'Translate Video'}</button>
      </form>
      {videoUrl && <video src={videoUrl} controls autoPlay style={{ maxHeight: "90vh", width: "auto" }}/>}
      {vVError && <div>{vVError}</div>}
      <div style={{ margin: '70px 0' }}></div>
      <h2>Convert Youtube video to another language text</h2>
      <form onSubmit={handleVTSubmit}>
        <input type="text" value={vTUrl} onChange={(e) => setVTUrl(e.target.value)} placeholder="Paste URL here" required />
        <select multiple value={selectedVTSourceLanguage} onChange={(e) => {
          const clickedOption = e.target.value;
          let updatedValues;

          // If the clicked option is already selected, deselect it
          if (selectedVTSourceLanguage.includes(clickedOption)) {
            updatedValues = selectedVTSourceLanguage.filter(option => option !== clickedOption);
          } else { // If the clicked option is not selected, select it
            updatedValues = [...selectedVTSourceLanguage, clickedOption];
          }
          setSelectedVTSourceLanguage(updatedValues);
        }} required>
          <option value="">Select Source Language(s)</option>
          {languages.map((language) => (
            <option key={language.code} value={language.code}>{language.language}</option>
          ))}
        </select>
        <select value={selectedVTTargetLanguage} onChange={(e) => setSelectedVTTargetLanguage(e.target.value)} required>
          <option value="">Select Target Language</option>
          {languages.map((language) => (
            <option key={language.code} value={language.code}>{language.language}</option>
          ))}
        </select>
        <button type="submit" disabled={vTLoading}>{vTLoading ? 'Translating to Text...' : 'Translate to Text'}</button>
      </form>
      {vTTranslatedText && <div>{vTTranslatedText}</div>}
      {vTError && <div>{vTError}</div>}
      <div style={{ margin: '70px 0' }}></div>
      <h2>Convert audio to text</h2>
      <form onSubmit={handleATSubmit}>
        <input type="text" value={aTUrl} onChange={(e) => setATUrl(e.target.value)} placeholder="Paste URL here" required />
        <select multiple value={selectedATSourceLanguage} onChange={(e) => {
          const clickedOption = e.target.value;
          let updatedValues;

          // If the clicked option is already selected, deselect it
          if (selectedATSourceLanguage.includes(clickedOption)) {
            updatedValues = selectedATSourceLanguage.filter(option => option !== clickedOption);
          } else { // If the clicked option is not selected, select it
            updatedValues = [...selectedATSourceLanguage, clickedOption];
          }
          setSelectedATSourceLanguage(updatedValues);
        }} required>
          <option value="">Select Source Language(s)</option>
          {languages.map((language) => (
            <option key={language.code} value={language.code}>{language.language}</option>
          ))}
        </select>
        <select value={selectedATTargetLanguage} onChange={(e) => setSelectedATTargetLanguage(e.target.value)} required>
          <option value="">Select Target Language</option>
          {languages.map((language) => (
            <option key={language.code} value={language.code}>{language.language}</option>
          ))}
        </select>
        <button type="submit" disabled={aTLoading}>{aTLoading ? 'Translating to Text...' : 'Translate to Text'}</button>
      </form>
      {aTTranslatedText && <div>{aTTranslatedText}</div>}
      {aTError && <div>{aTError}</div>}
      <div style={{ margin: '70px 0' }}></div>
      <h2>Convert Text to Audio</h2>
      <form onSubmit={handleTASubmit}>
        <input type="text" value={tAText} onChange={(e) => setTAText(e.target.value)} placeholder="Text to Convert" required />
        <select value={selectedTATargetLanguage} onChange={(e) => setSelectedTATargetLanguage(e.target.value)} required>
          <option value="">Select Target Language</option>
          {languages.map((language) => (
            <option key={language.code} value={language.code}>{language.language}</option>
          ))}
        </select>
        {selectedTATargetLanguage && (
          <select value={selectedTAVoice} onChange={(e) => setSelectedTAVoice(e.target.value)} required>
            <option value="">Select Target Voice</option>
            {getVoices(selectedTATargetLanguage).map((voice) => (
              <option key={voice.voice} value={voice.voice}>{voice.gender} - {voice.voice}</option>
            ))}
          </select>
        )}
        <button type="submit" disabled={tALoading}>{tALoading ? 'Translating to Audio...' : 'Translate to Audio'}</button>
      </form>
      {audioUrl && (<audio src={audioUrl} controls autoPlay style={{ maxWidth: '100%' }} />)}
      {tAError && <div>{tAError}</div>}
      <div style={{ margin: '70px 0' }}></div>
      <h2>Convert Text to Text</h2>
      <form onSubmit={handleTTSubmit}>
        <input type="text" value={tTText} onChange={(e) => setTTText(e.target.value)} placeholder="Text to Translate" required />
        <select value={selectedTTTargetLanguage} onChange={(e) => setSelectedTTTargetLanguage(e.target.value)} required>
          <option value="">Select Target Language</option>
          {languages.map((language) => (
            <option key={language.code} value={language.code}>{language.language}</option>
          ))}
        </select>
        <button type="submit" disabled={tTLoading}>{tTLoading ? 'Translating Text...' : 'Translate Text'}</button>
      </form>
      {tTTranslatedText && <div>{tTTranslatedText}</div>}
      {tTError && <div>{tTError}</div>}
      <div style={{ margin: '70px 0' }}></div>
    </div>
  );
}

export default App;
